import { Component, input, OnChanges, OnInit, output, SimpleChanges, ViewChild } from '@angular/core';
import {
  ContextService,
  DateFilterCellComponent,
  FilterService,
  SizingOptionsService,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ComboBoxComponent, DropDownFilterSettings, FilterDirective } from '@progress/kendo-angular-dropdowns';
import { Entity } from '@core/models/entities/Entity';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'rc-combobox-filter',
  standalone: true,
  imports: [ComboBoxComponent, FilterDirective, AsyncPipe],
  templateUrl: './combobox-filter.component.html',
  styleUrl: './combobox-filter.component.scss',
})
export class ComboboxFilterComponent<T extends Entity> extends DateFilterCellComponent implements OnInit, OnChanges {
  field = input.required<string>();
  textField = input.required<string>();
  valueField = input.required<string>();
  filterChange = output<string>();
  valueListObservable$ = input.required<Observable<T[]>>();

  value: T | null;
  @ViewChild(ComboBoxComponent) ComboBoxComponent: ComboBoxComponent;
  filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  constructor(filterService: FilterService, ctx: ContextService, sizingOptionService: SizingOptionsService) {
    super(filterService, ctx, sizingOptionService);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  setFilter(value: T | null): void {
    this.applyFilter(
      value === null || value === undefined
        ? this.removeFilter(this.field())
        : this.updateFilter({
            field: this.field(),
            operator: 'eq',
            value: value[this.valueField() as keyof T],
          }),
    );
  }

  getData(entries: T[] | null) {
    if (entries) {
      return entries;
    }
    return [];
  }

  setData(event: string) {
    this.filterChange.emit(event);
  }

  clearInputValue() {
    this.ComboBoxComponent.clearValue();
  }

  setValue(value: string) {
    this.ComboBoxComponent.value = value;
  }

  clearFilter() {
    this.clearInputValue();
  }

  protected override removeFilter(field: string): CompositeFilterDescriptor {
    this.value = null;
    return super.removeFilter(field);
  }

  doNothing() {
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) {
      this.value = this.filterByField(this.field())?.value;
    }
  }
}
